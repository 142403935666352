// @ts-nocheck
import React, { useEffect, useState, useCallback } from 'react';
import { Amplify } from 'aws-amplify';
import * as Auth from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import _ from 'lodash';
import api, { CookieName } from '../services';
import { actions } from '../redux';
import { connect } from 'react-redux';
import { getUrlParams, getQueryString } from '../utils';
import { storeEnrollmentLocalStorage } from '../services';
import { logError } from '../utils/errorLogger';
import { getUserAgentDetails, getVisitorId } from '../utils/fingerprint';
import { useTranslation } from 'react-i18next';
import {
  AWS_COGNITO_PROVIDER,
  COOKIE_PARTICIPANT_AUTH,
  STORAGE_PARTICIPANT_AUTH_EXPIRATION,
} from '../constants';
import { Banner } from '@evidation/ui';
import { addDays, isBefore } from 'date-fns';
import { AuthError } from 'aws-amplify/auth';

export const cookie = `${CookieName}_utm`;

const utmParams = [
  'referrer',
  'utm_content',
  'utm_medium',
  'utm_source',
  'utm_targeting',
  'utm_term',
  'utm_campaign',
];

/**
 * @description When function is run it checks the current URL for the presence of
 * query parameters. If they are present it will filter out specific keys in the
 * list of parameters and their values. Then store the parameters as a Cookie with
 * and expiration of one day.
 **/
export const storeUtmParams = () => {
  const urlParams = getUrlParams(window.location.search);

  const filteredParams = utmParams.reduce((result, param) => {
    if (urlParams[param]) {
      result[param] = urlParams[param];
    }
    return result;
  }, {});

  if (Object.keys(filteredParams).length > 0) {
    api.cookie.set(cookie, JSON.stringify(filteredParams), {
      expires: 1,
    });
  }
};

/**
 * Whether the a user has created a third party account and we need to
 * trigger the login process. We test this by checking the relevant
 * query parameter.
 *
 */
export const wasThirdPartyAccountCreated = (authProvider: string) => {
  return (
    authProvider &&
    getQueryString('idp_login').toLowerCase() === authProvider.toLowerCase()
  );
};

export const CognitoLogin = ({
  getMeta,
  getUser,
  meta: { slug, authentication },
  setIsLoading,
  addError,
  children,
}) => {
  const shouldHoldRender = () => {
    if (
      window.location.pathname.includes('/manage') &&
      api.local.get(COOKIE_PARTICIPANT_AUTH)
    ) {
      return true;
    }
    return false;
  };
  const prevSession = api.local.get(COOKIE_PARTICIPANT_AUTH);
  if (prevSession && prevSession.slug && prevSession.slug !== slug) {
    api.local.remove(COOKIE_PARTICIPANT_AUTH);
    api.local.remove(STORAGE_PARTICIPANT_AUTH_EXPIRATION);
  }
  const [cognitoInitialized, setCognitoInitialized] = useState(null);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const [token, setToken] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [localLoading, setLocalLoading] = useState(shouldHoldRender());

  const handleLogin = useCallback(() => {
    setIsLoading(true);
    Auth.signOut();
    Auth.signInWithRedirect({ provider: getQueryString('idp_login') });
  }, [setIsLoading]);
  const redirectSignIn = `${window.location.origin}/${slug}/`;

  useEffect(() => {
    if (
      authentication?.provider?.toLowerCase() === AWS_COGNITO_PROVIDER &&
      !cognitoInitialized
    ) {
      if (authentication.details) {
        const {
          cognito_client_id: clientId,
          cognito_domain: domain,
          cognito_region: region,
          cognito_userpool_id: userPoolId,
          response_type: responseType,
          scope,
          logout,
        } = authentication.details;

        Amplify.configure({
          Auth: {
            Cognito: {
              region,
              userPoolId,
              clientId,
              userPoolClientId: clientId,
              logout,
              loginWith: {
                oauth: {
                  domain,
                  scopes: scope,
                  redirectSignIn: [redirectSignIn],
                  redirectSignOut: [logout],
                  responseType,
                },
              },
            },
          },
        });

        setCognitoInitialized(true);
      } else {
        logError(
          `Authentication details not provided so cognito could not initialize Current Value:`,
          authentication,
        );
        setCognitoInitialized(false);
      }
    }
  }, [redirectSignIn, cognitoInitialized, authentication, token]);

  useEffect(() => {
    const handleSessionCheck = async () => {
      if (cognitoInitialized) {
        const session = await Auth.fetchAuthSession();
        if (session.tokens) {
          setToken({
            access_token: session.tokens.accessToken.toString(),
            id_token: session.tokens.idToken.toString(),
          });
          // Wait for custom JavaScript to load since it may define addTokenFetchCallback.
          await window.customHead.loaded();
          await window.customBody.loaded();
          if (
            window.addTokenFetchCallback !== undefined &&
            typeof window.addTokenFetchCallback === 'function'
          ) {
            window.addTokenFetchCallback(session.tokens.idToken);
          }
        }
      }
    };
    handleSessionCheck();
  }, [cognitoInitialized]);

  useEffect(() => {
    if (cognitoInitialized) {
      const query = new URLSearchParams(window.location.search);
      if (query.get('identity_provider') && !token?.access_token) {
        if (
          authentication?.identity_providers.find(
            (v: { name: string }) => v.name === query.get('identity_provider'),
          )
        ) {
          const idp = query.get('identity_provider');
          Auth.signInWithRedirect({ provider: { custom: idp } }).catch(err => {
            if (err instanceof AuthError) {
              if (err.name === "UserAlreadyAuthenticatedException") {
                // do nothing if the user is already logged in. they will get redirected
              }
            }
          });
        } else {
          setError(
            'Invalid identity provider, please click the button for your login provider to login again.',
          );
        }
      }
    }
  }, [cognitoInitialized, token, authentication.identity_providers]);

  useEffect(() => {
    const listener = async ({ payload: { event, ...rest } }) => {
      switch (event) {
        case 'signedIn':
          const session = await Auth.fetchAuthSession();
          setToken({
            access_token: session.tokens.accessToken.toString(),
            id_token: session.tokens.idToken.toString(),
          });
          // Wait for custom JavaScript to load since it may define addTokenFetchCallback.
          await window.customHead.loaded();
          await window.customBody.loaded();

          if (
            window.addTokenFetchCallback !== undefined &&
            typeof window.addTokenFetchCallback === 'function'
          ) {
            window.addTokenFetchCallback(session.tokens.idToken);
          }
          break;
      }
    };

    const remove = Hub.listen('auth', listener);

    storeUtmParams();

    // This handles the case if a user has created a third party account,
    // it will trigger the login process after account creation.
    if (wasThirdPartyAccountCreated(authentication.provider)) {
      handleLogin();
    }

    return () => {
      remove();
    };
  }, [authentication.provider, handleLogin, setIsLoading]);

  useEffect(() => {
    const getTriageToken = async () => {
      const userAgentDetails = getUserAgentDetails();
      const visitorId = await getVisitorId();
      const utmCookie = api.cookie.get(cookie);
      const analytics = utmCookie ? JSON.parse(utmCookie) : {};
      const prevSessionInfo = api.local.get(COOKIE_PARTICIPANT_AUTH);
      const prevAuthExpiration = api.local.get(
        STORAGE_PARTICIPANT_AUTH_EXPIRATION,
      );
      if (
        prevSessionInfo?.enrollment_identifier &&
        prevSessionInfo?.participant_auth_token
      ) {
        if (
          prevAuthExpiration &&
          isBefore(new Date(), new Date(prevAuthExpiration))
        ) {
          setUserInfo(prevSessionInfo);
          return;
        }
      }
      api
        .cognito(slug, {
          ...token,
          analytics,
          meta: {
            userAgent: userAgentDetails,
            visitorId,
          },
        })
        .then(({ data, headers }) => {
          if (headers?.['participant-auth-token-expires']) {
            api.local.set(
              STORAGE_PARTICIPANT_AUTH_EXPIRATION,
              headers['participant-auth-token-expires'],
            );
          } else {
            // simplify case where triage doesnt have a timeout or doesnt send a header.
            // just assume the token basically doesnt expire
            api.local.set(
              STORAGE_PARTICIPANT_AUTH_EXPIRATION,
              addDays(new Date(), 365),
            );
          }
          setUserInfo({ ...data, slug });
        })
        .catch((error) => {
          logError(error);
          const message =
            error.response?.data?.error ?? t('components.error.defaultError');
          setError(message);
          addError(new Error(message));
          setIsLoading(false);
        });
    };

    if (!_.isEmpty(token)) {
      getTriageToken();
    }
  }, [slug, token, setUserInfo, setIsLoading, addError, t]);

  useEffect(() => {
    let isCancelled = false;
    const loginUser = async ({
      participant_auth_token,
      enrollment_identifier,
      slug,
    }) => {
      if (!isCancelled) {
        getMeta(participant_auth_token, enrollment_identifier);
        const { payload, error = false } = await getUser(
          enrollment_identifier,
          participant_auth_token,
        );
        if (!error) {
          storeEnrollmentLocalStorage({ ...payload, slug });
        }
        if (error) {
          setError(payload?.json?.message ?? 'Something went wrong');
        }
        setLocalLoading(false);
        setIsLoading(false);
      }
    };

    if (
      _.has(userInfo, 'participant_auth_token') &&
      _.has(userInfo, 'enrollment_identifier') &&
      _.has(userInfo, 'slug')
    ) {
      loginUser(userInfo);
    }

    return () => {
      isCancelled = true;
    };
  }, [userInfo, getMeta, getUser, setIsLoading]);

  return cognitoInitialized === false ? (
    <Banner
      // hard false check here since null means it hasnt checked yet.
      show={cognitoInitialized === false}
      variant="error"
      testID="Cognito-Not-Initalized--Banner"
      style={{ zIndex: 20 }}
    >
      Unable to Initialize Amplify, see console for details
    </Banner>
  ) : (
    <>
      <Banner
        show={error ? true : false}
        variant="error"
        testID="Cognito-Error--Banner"
        style={{ zIndex: 120 }}
      >
        {error}
      </Banner>
      <div data-testid="Cognito-Wrapper">
        {!localLoading && children}
      </div>
    </>
  );
};

export default connect(
  ({ meta, participant }) => ({
    meta,
    authTokenExpiration: participant.auth_token_expiration,
    participantAuthToken: participant.participant_auth_token,
  }),
  (dispatch) => ({
    getMeta: (auth_token, enrollment_identifier) =>
      dispatch(actions.getMeta(auth_token, enrollment_identifier)),
    getUser: (enrollment_id, auth_token) =>
      dispatch(actions.getUser(enrollment_id, auth_token)),
    addError: (error) => dispatch(actions.addError(error)),
  }),
)(CognitoLogin);
